<template>
  <div class="container">
    <div class="card card-custom shadow-none">
      <!-- begin::Loading -->
      <template v-if="isMounted === false || isLoading">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </template>
      <!-- end::loading -->

      <!-- begin::Errors alert -->
      <template v-if="isErrorActive">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop" />
          <div class="loading-block w-75">
            <error-translation-alert
              ref="errorTranslationAlert"
              :error="activeError"
              class="shadow"
            />
          </div>
        </div>
      </template>
      <!-- end::Error alert -->

      <!-- begin::Card header -->
      <div class="card-header flex-wrap">
        <div class="card-title">
          <h3 class="card-label">
            Sessions
            <span class="text-muted pt-2 font-size-sm d-block">Sessions of device.</span>
          </h3>
        </div>

        <div class="card-toolbar w-100 w-md-auto">
          <b-dropdown
            ref="range-dropdown"
            variant="primary"
            class="btn-block btn-clean btn-dropdown dropdown-menu-top-unround"
            right
            lazy
            no-caret
            menu-class="w-100 w-md-500px"
          >
            <template v-slot:button-content>
              <i class="mt-n1">
                <font-awesome-icon :icon="['fas', 'calendar-alt']" />
              </i>

              <span class="pl-3">
                <template v-if="$moment.tz(datePicker.range.start, datePicker.timezone).isSame(datePicker.range.end, 'date')">{{ $moment.tz(datePicker.range.start, datePicker.timezone).format("LL") }}</template>
                <template v-else>{{ $moment.tz(datePicker.range.start, datePicker.timezone).format("LL") }} - {{ $moment.tz(datePicker.range.end, datePicker.timezone).format("LL") }}</template>
              </span>
            </template>

            <template v-slot:default>
              <div class="row mx-0">
                <div class="col-12 border-bottom col-md-8 border-bottom-md-0 border-right-md">
                  <date-picker
                    mode="date"
                    class="border-0 my-auto"
                    color="primary"
                    locale="en"
                    is-range
                    is-expanded

                    :value="datePicker.range"
                    :min-date="datePicker.minDate"
                    :max-date="datePicker.maxDate"
                    :input-props="{ class: 'border-0' }"
                    :first-day-of-week="2"
                    :timezone="datePicker.timezone"

                    @input="onDatePickerInput"
                  />
                </div>

                <div class="col pt-5 pt-md-0">
                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).startOf('day').toDate(),
                      end: $moment.tz(datePicker.timezone).endOf('day').toDate()
                    })"
                  >
                    Today
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).subtract(1, 'days').startOf('day').toDate(),
                      end: $moment.tz(datePicker.timezone).subtract(1, 'days').endOf('day').toDate()
                    })"
                  >
                    Yesterday
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).startOf('isoWeek').toDate(),
                      end: $moment.tz(datePicker.timezone).toDate()
                    })"
                  >
                    This week
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).subtract(1, 'isoWeek').startOf('isoWeek').toDate(),
                      end: $moment.tz(datePicker.timezone).subtract(1, 'isoWeek').endOf('isoWeek').toDate()
                    })"
                  >
                    Last week
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).startOf('month').toDate(),
                      end: $moment.tz(datePicker.timezone).toDate()
                    })"
                  >
                    This month
                  </div>

                  <div
                    class="btn btn-outline-primary btn-block"
                    @click.prevent="onDatePickerInput({
                      start: $moment.tz(datePicker.timezone).subtract(1, 'month').startOf('month').toDate(),
                      end: $moment.tz(datePicker.timezone).subtract(1, 'month').endOf('month').toDate()
                    })"
                  >
                    Last month
                  </div>
                </div>
              </div>
            </template>
          </b-dropdown>
        </div>
      </div>

       <!-- begin::Chart -->
      <apexchart
        ref="chart"
        class="card-rounded-bottom h-400 mt-auto"
        :type="chart.type"
        :height="chart.height"
        :options="chart.options"
        :series="chart.series"
      />
      <!-- end::Chart -->
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/plugins/datepicker/index.scss';
</style>

<script>
import ApexCharts from 'vue-apexcharts';
import moment from 'moment-timezone';
import Equipment from '@/libs/classes/equipment';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import { errorComponentMixin } from '@vedicium/core-vue';

import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [equipmentMixin, errorComponentMixin],
  components: {
    errorTranslationAlert,

    DatePicker,
    apexchart: ApexCharts,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      data: {
        sessions: [],
        session_times: [],
      },
      datePicker: {
        range: {
          start: null,
          end: null,
        },
        timezone: this.equipment.location.timezone || 'UTC',
        minDate: moment.tz(this.equipment.location.timezone || 'UTC').set('year', 2016).startOf('year').toDate(),
        maxDate: new Date(),
        attributes: [{
          bar: {
            class: 'bg-primary',
          },
          highlight: {
            class: 'bg-primary',
            contentClass: 'bg-success',
          },
        }],
      },

      chart: {
        type: 'area',
        height: 400,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          plotOptions: {},
          colors: [this.$metronic.config.layout.get('colors.theme.base.primary'), this.$metronic.config.layout.get('colors.theme.base.brand')],
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'solid',
            opacity: [0.5, 0],
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary'), this.$metronic.config.layout.get('colors.theme.base.brand')],
          },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
            width: 3,
            colors: [this.$metronic.config.layout.get('colors.theme.base.primary'), this.$metronic.config.layout.get('colors.theme.base.brand')],
          },
          xaxis: {
            type: 'datetime',
            min: null,
            max: null,
            datetimeUTC: false,
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: true,
              formatter: (timestamp) => moment.tz(timestamp, this.datePicker.timezone).format('DD MMM'),
            },
            tooltip: {
              enabled: false,
              style: {
                colors: this.$metronic.config.layout.get('colors.gray.gray-500'),
                fontSize: '12px',
                fontFamily: this.$metronic.config.layout.get('font-family'),
              },
            },
          },
          yaxis: [{
            seriesName: 'Sessions',
          }, {
            opposite: true,
            seriesName: 'Session time',
            labels: {
              show: true,
              formatter: (value) => `${value} minutes`,
            },
          }],
          markers: {
            colors: [this.$metronic.config.layout.get('colors.theme.light.primary'), this.$metronic.config.layout.get('colors.theme.base.brand')],
            strokeColor: [this.$metronic.config.layout.get('colors.theme.base.primary'), this.$metronic.config.layout.get('colors.theme.base.brand')],
            strokeWidth: 3,
          },
        },
        series: [],
      },
    };
  },

  async mounted () {
    // Set start and end from range
    this.$set(this.datePicker, 'range', {
      start: moment.tz(this.datePicker.timezone).startOf('month').toDate(),
      end: moment.tz(this.datePicker.timezone).endOf('day').toDate(),
    });

    try {
      await this.getChartData();
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      return;
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async onDatePickerInput (range = null) {
      if (!range || !range.start || !range.end) {
        return;
      }

      if (range.start instanceof Date === false || range.end instanceof Date === false) {
        return;
      }

      // Hide dropdown and set range
      this.$refs['range-dropdown'].hide();
      this.$set(this.datePicker, 'range', {
        start: moment.tz(range.start, this.datePicker.timezone).startOf('day').toDate(),
        end: moment.tz(range.end, this.datePicker.timezone).endOf('day').toDate(),
      });

      await this.$nextTick();

      // Reload chart data
      try {
        await this.getChartData();
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this, ui_element: false });
      }
    },

    async getChartData () {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);
      this.$set(this, 'data', []);

      try {
        this.$set(this, 'data', await this.$ws.get(`${Equipment.uri}/statistics/sessions/daily/${this.datePicker.range.start.getTime()}/${this.datePicker.range.end.getTime()}`, {
          query: {
            equipment: (this.equipment ? this.equipment._meta.guid : ''),
            offset: this.datePicker.timezone,
            include_session_times: true,
          },
        }));
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.configureChartData(this.data ? this.data.results : []);

      return this.data;
    },
    async configureChartData (results = null) {
      // Set date range
      this.$set(this.chart.options.xaxis, 'min', moment.tz(this.datePicker.range.start, this.datePicker.timezone).valueOf());
      this.$set(this.chart.options.xaxis, 'max', moment.tz(this.datePicker.range.end, this.datePicker.timezone).startOf('day').valueOf());

      const series = [{
        name: 'Sessions',
        data: [],
      }, {
        name: 'Session time',
        data: [],
      }];

      // Loop through range
      const rangeLoop = {
        start: moment.tz(this.datePicker.range.start, this.datePicker.timezone),
        end: moment.tz(this.datePicker.range.end, this.datePicker.timezone),
      };

      do {
        const date = rangeLoop.start.clone();
        series[0].data.push([
          date.valueOf(),
          (results || []).filter((result) => date.isSame(result.date, 'date'), []).map((result) => result.total, 0).reduce((a, b) => a + b, 0),
        ]);

        series[1].data.push([
          date.valueOf(),
          (results || []).filter((result) => date.isSame(result.date, 'date'), []).map((result) => Math.round(result.session_time / 60), 0).reduce((a, b) => a + b, 0),
        ]);
      } while (rangeLoop.start.add(1, 'day').diff(rangeLoop.end) < 0);

      this.$set(this.chart, 'series', series);
    },
  },
};
</script>
